<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog
        :value="showDialog"
        :persistent="false"
        width="600"
        @input="closeDialog"
    >
      <v-card>
        <v-card-title class="headline">
          <span><i
              class="fa fa-tag"></i> {{ currentItem ? 'Редактирование категории товаров' : 'Добавление категории товаров' }}</span>
        </v-card-title>

        <v-card-text>
          <form>
            <v-layout column>
              <ValidationProvider name="type" rules="required" v-slot="{invalid, validated}">
                <v-select
                    @change="selectType"
                    v-model="type"
                    :items="types"
                    item-value="id"
                    item-text="name"
                    label="Тип *"
                    hide-details="auto"
                    required
                    :error="invalid && validated"
                ></v-select>
              </ValidationProvider>

              <ValidationProvider name="section_id" rules="required" v-slot="{invalid, validated}">
                <v-select
                    :disabled="!type"
                    v-model="section_id"
                    :items="sectionListShort"
                    item-value="id"
                    item-text="name"
                    label="Разделы *"
                    hide-details="auto"
                    required
                    :error="invalid && validated"
                ></v-select>
              </ValidationProvider>

              <ValidationProvider name="name" rules="required" v-slot="{invalid, validated}">
                <v-text-field
                    :error="invalid && validated"
                    label="Название *"
                    hide-details="auto"
                    v-model="name"
                ></v-text-field>
              </ValidationProvider>
            </v-layout>
          </form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton @click="handleSubmit(submit)" color="success">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span></CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import api from '@/api';
import {Dealer, Subdivision} from "@/config/product_types";

export default {
  name: "category-create-update-modal",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  mixins: [EntityModalMixin],
  mounted() {
    if (this.type) {
      this.fetchSectionListShort(this.type);
    }
  },
  data() {
    let state = {
      type: '',
      types: [{id: Dealer, name: 'Дилер'}, {id: Subdivision, name: 'Структурное подразделение'}],
      section_id: '',
      name: ''
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...this.currentItem
      }
    }

    return state;
  },
  methods: {
    createEntity: api.catalog.createCategory,
    updateEntity: api.catalog.updateCategory,
    ...mapActions('catalog', ['fetchSectionListShort']),
    async selectType(type) {
      await this.fetchSectionListShort(type);
      const initialSectionId = this.initialSectionId;

      if (initialSectionId && this.sectionListShort.some(({id}) => id === initialSectionId)) {
        this.section_id = initialSectionId;
      } else {
        this.section_id = '';
      }
    }
  },
  computed: {
    ...mapState('catalog', ['sectionListShort']),
    data() {
      return {
        name: this.name,
        section_id: this.section_id,
        type: this.type
      }
    },
    initialSectionId() {
      return this.currentItem ? this.currentItem['section_id'] : null;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
